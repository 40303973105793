import { useTranslation } from "react-i18next";
import { appSettings } from "src/config";
import "./FooterModule.scss";

const FooterModule = () => {
  const { t } = useTranslation();

  const handleCookiebot = () => {
      // @ts-ignore
      if (window && window.Cookiebot) {
          // @ts-ignore
          window.Cookiebot.show();
      }
  }

  return (
    <div className="FooterModule">
      <div className="content">
        <div className="logos-part">
            <a
                href={appSettings.nadacePromenyUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`footer-logo logo-promeny`}
            >
                <img src="/logos/promeny_v3.png" alt="Nadace Proměny" />
            </a>
        </div>

        <div className="links-part">
            <div className="links-part-row links-part-fb">
            <a
                className="facebook-link"
                href={appSettings.facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src="/logos/facebook.svg" alt="Facebook" />
            </a>
            </div>
            <div className="links-part-row links-part-green">
                <a href={`/aboutUs`}>{t("aboutUs")}</a>
                <a href={`/aboutProject`}>{t("aboutProject")}</a>
                {/* <a href={appSettings.newsletter}>{t("newsletter")}</a> */}
                <a href={`/contacts`}>{t("contacts")}</a>
            </div>
            <div className="links-part-row links-part-gold">
                <button onClick={handleCookiebot}>{t('cookiesSetup')}</button>
                <a href={`/files/npkk_podminky_uzivani_webovych_stranek_20240925.pdf`} target={`_blank`}>{t('conditionsUsage')}</a>
                <a href={`/files/npkk_zasady_ochrany_osobnich_udaju_20240925.pdf`} target={`_blank`}>{t('conditionsPersonal')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterModule;
