import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      cz: {
        translation: {
          campainPreparing: "Zastřešuje",
          foundationName: "Proměny",
          foundationSubName: "nadace Karla Komárka",
          siteName: "Měsíc školních zahrad",
          activitiesSubHeader: "Inspirujte se našimi aktivitami a vezměte děti ven! Kdy? Až naprší a neuschne! Proč? Protože tématem letošního ročníku Měsíce školních zahrad je voda! Kápněte na to venku!",
          filter: "Filtruj aktivity:",
          activityHeader: "Aktivita",
          worksheetDownload: "Ke stažení",
          activityTab: "Aktivity",
          whyGetInvolved: "O co jde",
          gameRules: "Jak se zapojit",
          moreActivities: "Více aktivit",
          facebook: "Facebook",
          newsletter: "Newsletter",
          archive: "Minulé ročníky",
          contacts: "Kontakty",
          aboutUs: "O nás",
          aboutProject: "O Měsíci školních zahrad",
          footerText: "Kápněte na to venku!",
          conditionsUsage: 'Podmínky užívání webových stránek',
          conditionsPersonal: 'Zásady ochrany osobních údajů',
          rules: 'Jak se zapojit',
          project: 'O co jde',
          parents: 'Pro rodiče',
          parentsMenu: 'Pro rodiče',
          cookiesSetup: 'Nastavení cookies',
          types: {
            badej: 'Bádej',
            ostatni: 'Ostatní',
            tvor: 'Tvoř',
            zazij: 'Zažij',
          }
        },
      },
    },
    lng: "cz",
    fallbackLng: "cz",

    interpolation: {
      escapeValue: false,
    },
  });
